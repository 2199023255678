function formatCompanyName(companyName) {
  return companyName.trim().replace(/\s+/g, "-");
}

function standardizeToSlug(name) {
  if (typeof name !== "string") return name;
  name = name.toLowerCase();
  const sanitizedSlug = name
    .replace(/[\s<>#%"{}\|\\^[\]()"`!_;/?:@&=+$,."]+/g, "-")
    .replace(/-+/g, "-")
    .replace(/^-|-$/g, "");

  return sanitizedSlug;
}

let currentFilter = "stk";
let searchContainers;

document.addEventListener("DOMContentLoaded", () => {
  const stockButton = document.querySelector("#stocks");
  const mobstockButton = document.querySelector("#mob-stocks");
  const mfButton = document.querySelector("#mf");
  const mobmfButton = document.querySelector("#mob-mf");

  stockButton.style.backgroundColor = "#75ffff";
  stockButton.style.color = "black";

  mobstockButton.style.backgroundColor = "#75ffff";
  mobstockButton.style.color = "black";
  function handleButtonClick(button, filterValue) {
    if (currentFilter === filterValue) return;

    stockButton.style.backgroundColor = "";
    stockButton.style.color = "";
    mfButton.style.backgroundColor = "";
    mfButton.style.color = "";

    mobmfButton.style.backgroundColor = "";
    mobmfButton.style.color = "";

    mobstockButton.style.backgroundColor = "";
    mobstockButton.style.color = "";

    currentFilter = filterValue;
    button.style.backgroundColor = "#75ffff";
    button.style.color = "black";

    const searchContainers = document.querySelectorAll(".searchDiv");
    searchContainers.forEach((container) => {
      const searchInput = container.querySelector(".search-input");
      if (searchInput && searchInput.value.trim() !== "") {
        performSearch(searchInput.value.trim(), container, currentFilter);
      }
    });
  }
  
  stockButton.addEventListener("click", () => handleButtonClick(stockButton, "stk"));
  mfButton.addEventListener("click", () => handleButtonClick(mfButton, "mf"));
  
  mobstockButton.addEventListener("click", () => handleButtonClick(mobstockButton, "stk"));
  mobmfButton.addEventListener("click", () => handleButtonClick(mobmfButton, "mf"));

  const searchContainers = document.querySelectorAll(".searchDiv");

  function openDropdown(container) {
    const button = container.querySelector(".searchIcon");
    const searchBar = container.querySelector(".search-bar");
    const overlay = container.querySelector(".overlay");
    const searchInput = searchBar.querySelector(".search-input");
    const searchResults = container.querySelector(".search-results");

    button.addEventListener("click", function () {
      const isExpanded = searchBar.classList.contains("expanded");

      if (isExpanded) {
        searchBar.classList.remove("expanded");
        container.classList.remove("search-active");
        button.classList.add("search-icon");
        button.classList.remove("search-icon-closed");
        searchInput.value = "";
        searchResults.style.display = "none";
      } else {
        searchBar.classList.add("expanded");
        container.classList.add("search-active");
        button.classList.add("search-icon-closed");
        button.classList.remove("search-icon");
        searchInput.focus();
        searchResults.style.display = "none";
      }
    });

    if (overlay) {
      overlay.addEventListener("click", function () {
        searchBar.classList.remove("expanded");
        container.classList.remove("search-active");
        button.classList.add("search-icon");
        button.classList.remove("search-icon-closed");
        searchInput.value = "";
        searchResults.style.display = "none";
      });
    }

    if (searchInput) {
      searchInput.addEventListener("input", function () {
        searchInput.value = searchInput.value.replace(/[^a-zA-Z0-9\s]/g, "");

        if (searchInput.value.trim() === "") {
          searchResults.style.display = "none";
        } else {
          searchResults.style.display = "block";
          performSearch(searchInput.value.trim(), container, currentFilter);
        }
      });
    }
  }

  // Global search API function
  function performSearch(query, container, currentFilter) {
    const apiUrl = `${
      window._Torus.BFF_BASE_URL
    }/globalsearch?q=${query.toLowerCase()}&filter=${currentFilter}&page=1&limit=20`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        const resultsContainer = container.querySelector(
          ".search-results .trending-list"
        );
        if (resultsContainer) {
          resultsContainer.innerHTML = "";
          const filteredData = data.filter(
            (item) => item.companyname || item.schName
          );
          if (filteredData.length > 0) {
            filteredData.forEach((item) => {
              const resultItem = document.createElement("div");
              resultItem.className = "category-item";
              resultItem.innerHTML = `
                                <p>${item.companyname || item.schName}</p>
                                <span class="item-type">${item.sectorname === "ETF" ? "ETF" : currentFilter === "mf" ? "MF" : "Stocks"}</span>
                            `;
              resultItem.style.userSelect = "none";
              resultItem.style.cursor = "pointer";

              resultItem.addEventListener("click", () => {
                const companyslug = item.companySlug;
                const schemeslug = item.schName;
                const sectorname = item.sectorname;

                if (sectorname === "ETF") {
                  window.open(`/etf/${companyslug}`, "_blank");
                } else if (sectorname) {
                  window.open(`/stocks/${companyslug}-share-price`, "_blank");
                } else {
                  window.open(
                    `/mutual-funds/${standardizeToSlug(schemeslug)}`,
                    "_blank"
                  );
                }
              });
              resultsContainer.appendChild(resultItem);
            });
          } else {
            resultsContainer.innerHTML = `<div class="category-item"><p>No results found</p></div>`;
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching search results:", error);
      });
  }
  searchContainers.forEach(openDropdown);
});

document.addEventListener("click", function (event) {
  const searchContainers = document.querySelectorAll(".searchDiv");
  if (searchContainers && searchContainers.length > 0) {
    searchContainers.forEach((container) => {
      if (!container.contains(event.target)) {
        const searchBar = container.querySelector(".search-bar");
        const searchResults = container.querySelector(".search-results");
        const searchInput = container.querySelector(".search-input");
        const button = container.querySelector(".searchIcon");

        searchBar.classList.remove("expanded");
        container.classList.remove("search-active");

        if (button) {
          button.classList.add("search-icon");
          button.classList.remove("search-icon-closed");
        }
        if (searchInput) {
          searchInput.value = "";
        }
        if (searchResults) {
          searchResults.style.display = "none";
        }
      }
    });
  }
});