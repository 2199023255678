importScripts('https://storage.googleapis.com/workbox-cdn/releases/6.1.5/workbox-sw.js');
importScripts('https://s3-eu-west-1.amazonaws.com/static.wizrocket.com/js/sw_webpush.js');

// Ensure Workbox loaded successfully
if (workbox) {
    console.log(`Yay! Workbox is loaded 🎉`);
    // Precache manifest (replaced during build)
    workbox.precaching.precacheAndRoute(self.__WB_MANIFEST || []);

    // Network-first caching for JS and CSS (supporting .gz files)
    workbox.routing.registerRoute(
        ({ request }) => request.destination === 'script' || request.url.endsWith('.js.gz'),
        new workbox.strategies.NetworkFirst()
    );

    workbox.routing.registerRoute(
        ({ request }) => request.destination === 'style' || request.url.endsWith('.css.gz'),
        new workbox.strategies.NetworkFirst()
    );

    // Offline fallback for HTML documents
    workbox.routing.registerRoute(
        ({ request }) => request.mode === 'navigate',
        new workbox.strategies.NetworkFirst({
            cacheName: 'html-cache',
            plugins: [
                new workbox.expiration.ExpirationPlugin({
                    maxEntries: 10,
                }),
            ],
        })
    );

    // Log any other caches or strategies you might want to include
} else {
    console.log(`Workbox didn't load!`);
}
